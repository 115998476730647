import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Blocks from "../components/Blocks"

export default function IndexPage({ data }) {
  const page = data && data.page
  if (!page) return

  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
      />
      <section className="wrapper">
        <Blocks blocks={page.Blocks} _rawBlocks={page._rawBlocks} />
      </section>
    </>
  )
}

export const query = graphql`
  query indexQuery {
    page: sanityHomePage {
      _id
      title
      _rawBlocks
      Blocks {
        _key
        _type
        _rawContent(resolveReferences: { maxDepth: 10 })
        content {
          ...blocks
        }
        blockSettings {
          width
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`
